<!--
 * @Author: your name
 * @Date: 2020-10-20 18:18:00
 * @LastEditTime: 2020-10-21 09:42:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \GrandHyattc:\Users\25740\Desktop\jciweb\src\views\AuthPages\RecoverPasswordBefore.vue
-->
<template>
  <div class="RecoverPasswordBefore">
    <div class="titleBox d-flex align-items-center mb-4">
      <div class="imgBox6" @click="$router.go(-1)">
        <img src="../../assets/images/login/chevron-left.png" alt="" />
      </div>
      <div class="title">{{ $t("AuthPage.024@forgot_password") }}</div>
    </div>

    <!-- <form class="mt-4"> -->
    <div class="form-group">
      <label for="exampleInputEmail1">{{
        $t("AuthPage.7dc@email_address")
      }}</label>
      <input
        type="email"
        class="form-control mb-0"
        id="exampleInputEmail1"
        :placeholder="$t('AuthPage.7dc@email_address')"
        v-model="form.email"
      />
    </div>
    <div>
      <div class="d-flex justify-content-end">
        <button
          type="submit"
          class="btn btn-primary mt-3"
          @click="handleClickNext"
          v-show="!isDisabled"
        >
          {{ $t("AuthPage.038@next") }}
        </button>
      </div>

      <div class="desc" ref="desc">
        {{ $t("AuthPage.608@check_your_email_for") }}
      </div>
    </div>
    <!-- </form> -->
  </div>
</template>

<script>
export default {
  name: "RecoverPasswordBefore",
  data: () => ({
    isDisabled: false,
    form: {
      // 邮箱
      email: "",
    },
  }),
  mounted () { },
  methods: {
    handleClickNext () {
      if (this.form.email) {
        this.$http.forgotPassword({ email: this.form.email }).then((res) => {
          if (res.status == 200) {
            this.$refs.desc.style.display = "flex";
            this.isDisabled = true;
            // setTimeout(() => {
            //   this.isDisabled = false;
            // }, 60000);
            // this.$router.push({ name: "auth/password-reset" });
          }else{
            this.isDisabled = false;
          }
        });
      } else {
        this.$message.info($t('AuthPage.96b@mailbox_cannot_be_em'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.RecoverPasswordBefore {
  .titleBox {
    margin-bottom: 83px;
    position: relative;
    .imgBox6 {
      position: absolute;
      left: -10px;
      cursor: pointer;
    }
    .title {
      font-size: 28px;
      padding-left: 20px;
      font-weight: 400;
      color: #3f414d;
    }
  }
  .desc {
    display: none;
    align-items: center;
    justify-content: center;
    height: 45px;
    background: #f2f9ec;
    border-radius: 10px;
    color: #7FBE50;
    margin-top: 20px;
  }
}
@media (max-width: 578px) {
  .RecoverPasswordBefore {
    .desc {
      display: none;
      align-items: center;
      justify-content: center;
      height: 70px;
      background: #f2f9ec;
      border-radius: 10px;
      color: #7FBE50;
      margin-top: 20px;
      padding: 15px;
    }
  }
}
</style>
